import React from 'react';

//import css
import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';

import '@leenguyen/react-flip-clock-countdown/dist/index.css';

import videoBG from '../src/assets/video.mp4';

import '../src/styles.css';

const App = () => {
  return <section className='page'>
    {/* overlay */}

    <div className='overlay'></div>
    {/* videos */}
    <video src={videoBG} autoPlay loop muted></video>
    {/**/}
    <div className='page_content'>
      <h1>KENT ENERGY</h1>
      <h2>Launching Soon</h2>
      <h3>Leave you email and we will let you know once the site is ready</h3>
      <FlipClockCountdown to={new Date().getTime()+20 * 24* 3600 * 1000 + 5000}
    className ='flip-clock' labels={['DAYS', 'HOURS', 'MINUITES', 'SECONDS']} duration ={0.5} />  
    <button className='btn'>Notify Me</button>
    </div>

    
    

  </section>
};

export default App;
